import Head from "next/head";
import Searchbar from "../components/searchbar/searchbar";
import { useRouter } from "next/router";
import PageFooter from "../components/footer/page-footer";
import PageNavbar from "../components/navbar/navbar";
import styles from './app.module.css';
import { NavBarMobile } from "../components/navbar-mobile/navbar-mobile";
import logoWhite from './../public/logo-white.png';
import logoPink from './../public/logo.png';
import CartHandler from '../utils/shopping-cart-handler';
import Cart from "../components/cart/cart";
export default function MyApp({ Component, pageProps }: {Component: any, pageProps: any}) {
  const router = useRouter();
  const handleClick = (event: any) => {
    event.stopPropagation();
    router.push('/');
  }
  if (typeof document !== 'undefined') {
    CartHandler.init(document);
  }


  return (
	
	<>
    <Head>
      <title>Babymoon - Babyutstyr for en god start på foreldrelivet</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" /> 
      <meta name="tradetracker-site-verification" content="bc42414310b42b0c3578d3fa39be7e383bc03e30" />
      <meta name="keywords" content="babyutstyr, barneutstyr, barnevogn, ammeutstyr, babyklær"></meta>
      <meta name="description" content="Babymoon har samlet et større utvalg babyklær og utstyr fra en rekke produsenter og leverendører slik at du skal kunne få den beste prisen." />
      <meta name="og:title" content=">Babymoon - Babyutstyr for en god start på foreldrelivet" />
      <meta name="og:description" content="Babymoon har samlet et større utvalg babyklær og utstyr fra en rekke produsenter og leverendører slik at du skal kunne få den beste prisen." />
      <link rel="icon" type="image/png" href={logoPink.src} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Babymoon" />
      <link rel="canonical" href="https://www.babymoon.no" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="index" href="https://www.babymoon.no" />
      <script type="application/ld+json" dangerouslySetInnerHTML={
      {__html: `{
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://babymoon.no/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://babymoon.no/sok?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }`}} />
    </Head>
    <div className={styles.headerWrapper}> 
      <header className={styles.header}>
          <div className={styles.headerInner}>
            <img src={logoWhite.src} alt="Babymoon logo" style={{height: '24px'}} />
            <div style={{lineHeight: '18px', marginLeft: '8px', width: '100%'}} onClick={handleClick}>
              <span style={{fontSize: '18px', fontWeight: 600}}>Babymoon.no</span><br/>
              <span style={{fontSize: '13px', fontFamily: 'sans-serif'}}> - Babyutstyr for en god start på foreldrelivet.</span>
            </div>
            <div className="showInMobile showOnPad" style={{overflow: 'visible'}}><Cart /></div>
          </div>
        <PageNavbar/>
        <span className="hideInMobile hideOnPad"><Cart /></span>
      </header>
    </div>      
    <section className={styles.searchBarWrapper}>
        <Searchbar/>
    </section>
    <div className={router.pathname.toLowerCase().indexOf('sok') == -1 ? styles.mainWrapper : ''}>
      <Component {...pageProps} />
    </div>
    <NavBarMobile/>
    <PageFooter/> 
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
        
        html,
        body {
		      padding: 0;
		      margin: 0;
		      font-size: 16px;
          background: #faf5ef;
          font-family: 'Open Sans', sans-serif;
		      color: #212121;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;   
          scroll-behavior: smooth;       
        }
        .pinkHeader {
          background: #db3ca9;
          color: white;
          padding: 8px;
        }
        #__next {
          height: inherit;
        }
        * {
          box-sizing: border-box;
        }
        .showOnPad {
          display: none;
        }
        .hideInMobile {
          display: block;
        }
        .showInMobile {
          display: none;
        }          
        @media only screen and (max-width: 600px) {
          .hideInMobile {
            display: none;
          }
          .showInMobile {
            display: block;
            overflow: hidden;
            text-align: center;
          }            
        }
        @media only screen and (min-width: 600px) and (max-width: 1200px) {
          .hideOnPad {
            display: none;
          }
          .showOnPad {
            display: block;
          }          
        }      
      `}</style>
    </>
  )
}