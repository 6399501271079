import Link from "next/link";
import styles from './navbar.module.css';
export default function NavItem(props: {title: string, subitems: string[]}) {
	return (

		<li key={props.title}>
			<span>{props.title}</span>
			<ul className={styles.subMenu}>
				{props.subitems.map((i) => {
					return <li key={i}><Link href={`/sok?q=${i}`} passHref>{i}</Link></li>
				})}
			</ul>
		</li>

	)
}