import { useState } from 'react';
import styles from './navbar.module.css';
import Link from 'next/link';
import NavItem from './navitem';
export default function PageNavbar() {
	const [isSelected, setIsSelected] = useState(false);
	return (
		<nav className={styles.navbar}>
			<ul>
				<NavItem title="Transport og Reiser" subitems={["Barnevogner", "Bilstol", "Bæreseler"]}></NavItem>
				<NavItem title="Sikkerhet" subitems={["Babycall", "Sikkerhetsgrinder"]}></NavItem>
				<NavItem title="Soving" subitems={["Seng", "Madrasser", "Sengetøy", "Smokker"]}></NavItem>
				<NavItem title="Mat og Ernæring" subitems={["Amming", "Flasker", "Matstoler"]}></NavItem>
				<NavItem title="Helse og Velvære" subitems={["Badeutstyr", "Stell", "Pleieprodukter"]}></NavItem>
				<NavItem title="Klær og Tekstiler" subitems={["Bodyer", "Soveposer", "Tepper", "Yttertøy"]}></NavItem>
				<NavItem title="Leker og Underholdning" subitems={["Babygym", "Uteleker"]}></NavItem>
				<NavItem title="Møbler" subitems={["Oppbevaring", "Stoler", "Kommoder"]}></NavItem>
			</ul>
		</nav>
	)
}