import { useEffect, useState } from 'react';
import { IProduct } from '../../model/product';
import CartHandler from './../../utils/shopping-cart-handler';
import styles from './cart.module.css'
import cartIcon from './../../assets/shopping-cart.png'
export default function Cart() {
	const [cart, setCart] = useState<IProduct[]>([]);
	CartHandler.cartUpdated(() => {
		const cart = localStorage.getItem('cart');
		if (cart) {
			setCart(JSON.parse(cart));
		}
	});

	useEffect(() => {
		const cart = localStorage.getItem('cart');
		if (cart) {
			setCart(JSON.parse(cart));
		}
	}, []);

	if (!cart.length) {
		return <></>
	}

	return (
		<div className={styles.cartWrapper} onClick={() => window.location.href = "/handleliste"}>
			<img src={cartIcon.src} style={
				{
					height: '32px',
					filter: `${cart.length ? 'grayscale(0)' : 'grayscale(1)'}`,
				}
			} alt="cart" /><div className={styles.number}>{cart.length}</div>
		</div>
	)
}